import * as React from "react";
import { useParams, useNavigate } from 'react-router-dom';

import FmdBadIcon from "@mui/icons-material/FmdBad";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Page from "../../components/Page";
import SidebarTabPanel from "../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../components/SidebarTabPanelProps";

import Alerts from "./Alerts";
import CameraSidebar from "../Camera/CameraSidebar";

import { useApp } from "../../context/App";

function Alert() {
    const navigate = useNavigate();
    const { client, hasPermission, backdrop, reload, setSelectedPage } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
        navigate(`/alert`);
    };

    // PERMISSION CHECK
    const adminRoles = ["admin", "camera:admin"];
    const alertRoles = adminRoles.concat([
        "viewer", "editor", "camera:viewer", "camera:editor"
    ]);

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        const data = client.PlayerConfig();
        setPlayerConfig(data);
        return playerConfig;
    };

    // TABS
    const [tab, setTab] = React.useState(0);
    const handleChangeTag = (event, newValue) => {
        setTab(newValue);
    };

    const [alerts, setAlerts] = React.useState(null);
    const { camera_id } = useParams();
    const [cameras, setCameras] = React.useState(null);
    const [camera, setCamera] = React.useState(null);
    const handleSelectCamera = (camera) => {
			setCamera(camera);
			navigate(`/alert/${camera.id}`);
			setOpenSidebar(true);
    };

    React.useEffect(() => {
        setSelectedPage("alert");
    }, [setSelectedPage]);

    React.useEffect(() => {
        (async () => {
            setAlerts(null);
            setCamera(null);
            setOpenSidebar(false);
            if (tab === 0) {
                await getPlayerConfig();
                await client.GetCustomerAlert().then((data) => setAlerts(data));
                await client.GetCustomerCamera().then((data) => setCameras(data));
            }
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [tab, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (cameras && camera_id && !camera) {
            for (const c in cameras) {
                if (cameras[c].id === camera_id) {
                    handleSelectCamera(cameras[c]);
                }
            }
        }
    }, [cameras]);

    return (
        <Page
            drawerWidth={550}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                camera ? (
                    <CameraSidebar
                        key={camera.id}
                        drawerWidth={550}
                        camera={camera}
                        playerConfig={playerConfig}
                    />
                ) : null
            }
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            >
                <Tabs value={tab} onChange={handleChangeTag}>
                    <Tab
                        icon={<FmdBadIcon />}
                        iconPosition="start"
                        label="Störungen"
                        {...SidebarTabPanelProps(0)}
                        style={{ textTransform: "initial" }}
                        disabled={!hasPermission(alertRoles)}
                    />
                </Tabs>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    style={{
                        marginBottom: 5,
                    }}
                    spacing={2}
                >
                    {hasPermission(alertRoles) && alerts && cameras && (
                        <Alerts
                            alerts={alerts}
                            camera={camera}
                            cameras={cameras}
                            setCamera={setCamera}
                            playerConfig={playerConfig}
                            handleSelectCamera={handleSelectCamera}
                        />
                    )}
                </Stack>
            </SidebarTabPanel>
        </Page>
    );
}

Alert.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Alert.defaultProps = {
    openMenu: false,
};

export default Alert;
